// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titleForm{
    font-family: "Outfit", sans-serif;
    font-weight: 600;
    font-size: 28px;
    color: #000000;
    padding: 1px;
    margin-left: 1px;
}

.titleumum{                           /* Judul Halaman*/
    color: #000000;
    font-family: "Outfit", sans-serif;
    font-weight: bold;
    margin-left: 1px;
    margin-bottom: -20px;
    margin-top: 10px;
    padding-top: 1px;
    font-size: 32px;
}

.card-content{                       /* nyari atur form nya*/
    margin-left: 20px;
    margin-top: 6px;
}


.tombolSimpan{
    width: 100px;         /* Atur lebar */
    height: 35px;  
    margin-top: -30px;
    margin-right: 10px;
    
}

.control .tombolSimpan {
    font-size: 15px;
    
    font-family: "Outfit", sans-serif;
}



.paginationView span {
    margin: 0 8px;            /* Jarak antara teks dan tombol */
}

.paginationView button {
    padding: 3px 10px;        /* Mengatur besar tombol */
    font-size: 14px;           /* Menambah ukuran teks tombol */
}`, "",{"version":3,"sources":["webpack://./src/components/rmepenyakitumum/formaddumum.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,YAAY;IACZ,gBAAgB;AACpB;;AAEA,sCAAsC,iBAAiB;IACnD,cAAc;IACd,iCAAiC;IACjC,iBAAiB;IACjB,gBAAgB;IAChB,oBAAoB;IACpB,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;AACnB;;AAEA,qCAAqC,uBAAuB;IACxD,iBAAiB;IACjB,eAAe;AACnB;;;AAGA;IACI,YAAY,UAAU,eAAe;IACrC,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;;AAEtB;;AAEA;IACI,eAAe;;IAEf,iCAAiC;AACrC;;;;AAIA;IACI,aAAa,aAAa,iCAAiC;AAC/D;;AAEA;IACI,iBAAiB,SAAS,0BAA0B;IACpD,eAAe,YAAY,gCAAgC;AAC/D","sourcesContent":[".titleForm{\r\n    font-family: \"Outfit\", sans-serif;\r\n    font-weight: 600;\r\n    font-size: 28px;\r\n    color: #000000;\r\n    padding: 1px;\r\n    margin-left: 1px;\r\n}\r\n\r\n.titleumum{                           /* Judul Halaman*/\r\n    color: #000000;\r\n    font-family: \"Outfit\", sans-serif;\r\n    font-weight: bold;\r\n    margin-left: 1px;\r\n    margin-bottom: -20px;\r\n    margin-top: 10px;\r\n    padding-top: 1px;\r\n    font-size: 32px;\r\n}\r\n\r\n.card-content{                       /* nyari atur form nya*/\r\n    margin-left: 20px;\r\n    margin-top: 6px;\r\n}\r\n\r\n\r\n.tombolSimpan{\r\n    width: 100px;         /* Atur lebar */\r\n    height: 35px;  \r\n    margin-top: -30px;\r\n    margin-right: 10px;\r\n    \r\n}\r\n\r\n.control .tombolSimpan {\r\n    font-size: 15px;\r\n    \r\n    font-family: \"Outfit\", sans-serif;\r\n}\r\n\r\n\r\n\r\n.paginationView span {\r\n    margin: 0 8px;            /* Jarak antara teks dan tombol */\r\n}\r\n\r\n.paginationView button {\r\n    padding: 3px 10px;        /* Mengatur besar tombol */\r\n    font-size: 14px;           /* Menambah ukuran teks tombol */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
