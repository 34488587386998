// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/v870-tang-35.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kotakkonten {
    /*background: 	url('../assets/background.jpg') no-repeat; */
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center;
    background-size: cover; /* or contain depending on your needs */
}

/* <a href="https://www.freepik.com/free-vector/clean-medical-background_16352150.htm#from_view=detail_alsolike">Image by rawpixel.com on Freepik</a> */
.kotakkonten {
    margin-left: 243px; /* Sesuaikan dengan lebar sidebar */
    height: 100vh; /* Tinggi sesuai viewport */
    overflow-y: auto; /* Konten yang bisa di-scroll */
}

.content {
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/csspages/layoutpage.css"],"names":[],"mappings":"AAAA;IACI,2DAA2D;IAC3D,2EAAqE;IACrE,sBAAsB,EAAE,uCAAuC;AACnE;;AAEA,uJAAuJ;AACvJ;IACI,kBAAkB,EAAE,mCAAmC;IACvD,aAAa,EAAE,2BAA2B;IAC1C,gBAAgB,EAAE,+BAA+B;AACrD;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".kotakkonten {\r\n    /*background: \turl('../assets/background.jpg') no-repeat; */\r\n    background: url('../assets/v870-tang-35.jpg') no-repeat center center;\r\n    background-size: cover; /* or contain depending on your needs */\r\n}\r\n\r\n/* <a href=\"https://www.freepik.com/free-vector/clean-medical-background_16352150.htm#from_view=detail_alsolike\">Image by rawpixel.com on Freepik</a> */\r\n.kotakkonten {\r\n    margin-left: 243px; /* Sesuaikan dengan lebar sidebar */\r\n    height: 100vh; /* Tinggi sesuai viewport */\r\n    overflow-y: auto; /* Konten yang bisa di-scroll */\r\n}\r\n\r\n.content {\r\n    height: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
