// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-3{
 margin-left: 30px;
 font-family: "Outfit", sans-serif;
 font-weight: bold;
}

.title{
    color: #000000;
    font-family: "Outfit", sans-serif;
    font-weight: bold;
    margin-left: 1px;
    margin-bottom: 40px;
    margin-top: 1px;
    padding-top: 5px;
}

.title2{
    text-align: center;
}


.card-container{
    display: flex;
    justify-content: center;
}

`, "",{"version":3,"sources":["webpack://./src/components/dashboardcomp/home.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;CACjB,iCAAiC;CACjC,iBAAiB;AAClB;;AAEA;IACI,cAAc;IACd,iCAAiC;IACjC,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;;AAGA;IACI,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".p-3{\r\n margin-left: 30px;\r\n font-family: \"Outfit\", sans-serif;\r\n font-weight: bold;\r\n}\r\n\r\n.title{\r\n    color: #000000;\r\n    font-family: \"Outfit\", sans-serif;\r\n    font-weight: bold;\r\n    margin-left: 1px;\r\n    margin-bottom: 40px;\r\n    margin-top: 1px;\r\n    padding-top: 5px;\r\n}\r\n\r\n.title2{\r\n    text-align: center;\r\n}\r\n\r\n\r\n.card-container{\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
